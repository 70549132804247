import MediaSlider from "./MediaSlider";
import classNames from "classnames";
import Link from "next/link";

export default function MediaSections({
  sections,
  festivalId,
  forceGrid,
  displayMode,
  colorPalette = "default",
  hideIfEmpty,
  translateSectionTitles,
  ...rest
}) {
  return sections.map(
    ({ key, title, display_mode, view_more_target, items }, i) => {
      if (hideIfEmpty && items.length === 0) {
        return null;
      }

      const titleInnerContent = (
        <h2
          className={classNames(
            "group text-xl font-bold leading-7 text-white sm:text-2xl sm:leading-9 sm:truncate flex items-center",
            i > 0 && "mt-6",
            display_mode === "giant_slider" ? "mb-5" : "mb-4"
          )}
        >
          {key?.indexOf("hot_") === 0 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-6 h-6 mr-2"
            >
              <path
                fillRule="evenodd"
                d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                clipRule="evenodd"
              />
            </svg>
          ) : key === "hero" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-6 h-6 mr-2"
            >
              <path
                fillRule="evenodd"
                d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z"
                clipRule="evenodd"
              />
            </svg>
          ) : null}
          <span className={translateSectionTitles ? "" : "no-translate"}>
            {title}
          </span>
          {view_more_target ? (
            <div className="hidden sm:flex ml-3 items-center">
              <div className="bg-gray-600 text-gray-200 group-hover:bg-gray-500 group-focus:outline-none group-focus:shadow-outline-gray text-xs px-2 py-1 rounded font-bold transition duration-150 z-10 ease-in-out leading-snug whitespace-no-wrap">
                View more
              </div>
              <svg
                className="h-4 w-4 -ml-4 group-hover:ml-2 self-center transform scale-0 group-hover:scale-100 origin-left transition-all duration-200 ease-in-out"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                  fillRule="evenodd"
                ></path>
              </svg>
            </div>
          ) : null}
        </h2>
      );
      const titleContent = view_more_target ? (
        <div className="flex items-start">
          <Link href={view_more_target.href} as={view_more_target.as}>
            <a>{titleInnerContent}</a>
          </Link>
        </div>
      ) : (
        titleInnerContent
      );

      const mainContent = items.length ? (
        <MediaSlider
          items={items}
          displayMode={displayMode || display_mode}
          festivalId={festivalId}
          forceGrid={
            forceGrid ||
            (sections.length === 1 && sections[0].display_mode === "default")
          } // force single section to grid
          colorPalette={colorPalette}
          {...rest}
        />
      ) : (
        <div className="text-gray-100 text-center bg-gray-700 p-4 rounded-lg">
          <div>Nothing here, yet</div>
        </div>
      );

      return (
        <div key={title}>
          {titleContent}
          {mainContent}
        </div>
      );
    }
  );
}
