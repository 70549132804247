import classNames from "classnames";
import { useState, useEffect } from "react";
import Slider from "react-slick";

import MediaItem from "./MediaItem";
import MediaGrid from "./MediaGrid";
import ExpandedMediaDetail from "./ExpandedMediaDetail";
import { useGlobalExpandableManager } from "../hooks/useGlobalExpandableManager";

// import dynamic from "next/dynamic";
// const Slider = dynamic(() => import("react-slick"), {
//   ssr: false
// });

const Arrow = ({ className, style, direction, onClick, ...rest }) => {
  return (
    <div
      style={{
        maxWidth: 80,
        width: "15vw",
        top: 0,
        bottom: 0
      }}
      onClick={onClick}
      className={classNames(
        "absolute cursor-pointer z-10 text-gray-400 hover:text-white flex-col justify-center items-center",
        direction === "next" ? "right-0" : "left-0",
        `slider-${direction}-background hidden sm:flex`
      )}
    >
      <svg
        className="h-12 w-12"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 24 24"
      >
        {direction === "next" ? (
          <path
            d="M9 5L16 12L9 19"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        ) : (
          <path
            d="M15 19L8 12L15 5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
      </svg>
    </div>
  );
};

const NextArrow = props => <Arrow {...props} direction="next" />;
const PrevArrow = props => <Arrow {...props} direction="prev" />;

export default function MediaSlider({
  festivalId,
  displayMode = "default",
  items,
  forceGrid = false,
  colorPalette = "default",
  ...rest
}) {
  const [isClient, setIsClient] = useState(null);
  useEffect(() => {
    setIsClient(true);
  }, []);

  const { shownItem, setShownItem } = useGlobalExpandableManager();

  if (
    displayMode === "big_poster" ||
    displayMode === "default_grid" ||
    forceGrid
  ) {
    return (
      <MediaGrid
        festivalId={festivalId}
        displayMode={displayMode === "default_grid" ? "default" : displayMode}
        colorPalette={colorPalette}
        items={items}
        {...rest}
      />
    );
  }

  let initialCount, breakpoints;
  if (displayMode === "default") {
    initialCount = 4;
    breakpoints = [
      { width: 1024, count: 3 },
      { width: 600, count: 2 },
      { width: 480, count: 1 }
    ];
  } else if (displayMode === "poster") {
    initialCount = 7;
    breakpoints = [
      { width: 1250, count: 6 },
      { width: 1050, count: 5 },
      { width: 850, count: 4 },
      { width: 710, count: 3 },
      { width: 600, count: 2, scrollCount: 1 }
    ];
  } else if (displayMode === "cover" || displayMode === "giant_slider") {
    initialCount = 1;
    breakpoints = [];
  }

  const sliderSettings = {
    dots: false,
    infinite: items.length > initialCount,
    speed: 500,
    slidesToShow: initialCount,
    slidesToScroll: initialCount,
    initialSlide: 0,
    swipeToSlide: true,
    draggable: false,
    speed: displayMode === "giant_slider" ? 1000 : 500,
    autoplay: displayMode === "giant_slider",
    autoplaySpeed: displayMode === "giant_slider" ? 6000 : undefined,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: isClient
      ? breakpoints.map(({ width, count, scrollCount }) => ({
          infinite: true,
          breakpoint: width,
          settings: {
            slidesToShow: count,
            slidesToScroll: scrollCount || count
          }
        }))
      : null
  };

  return (
    <>
      <Slider
        key={isClient ? "client" : "server"}
        {...sliderSettings}
        className="-m-2 relative"
      >
        {items.map(item => (
          <MediaItem
            key={item.id}
            displayMode={displayMode}
            colorPalette={colorPalette}
            item={item}
            festivalId={festivalId}
            isSingle={items.length === 1}
            onHover={() => setShownItem(item)}
            {...rest}
          />
        ))}
      </Slider>
      {isClient ? (
        <ExpandedMediaDetail
          item={items.includes(shownItem) ? shownItem : null}
          festivalId={festivalId}
          {...rest}
        />
      ) : null}
    </>
  );
}
