export function generateNuggetsSummary(nuggets) {
  if (!nuggets) {
    return "";
  }

  const items = [
    nuggets.runtime && `${Math.ceil(nuggets.runtime / (1000 * 60))} min`,
    nuggets.year,
    nuggets.country
  ];
  return items.filter(x => !!x).join(" | ");
}
