import Transition from "./Transition";
import Button from "./Button";
import { useState, useLayoutEffect, useRef } from "react";
import Link from "next/link";

import { generateNuggetsSummary } from "../utils/formatters";

export default function ExpandedMediaDetail({
  item,
  festivalId,
  isDiscoveryList
}) {
  const ref = useRef();
  const [lastItem, setLastItem] = useState({});
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    if (item) {
      setLastItem(item);
    }
    setHeight(item ? ref.current.scrollHeight : 0);
  }, [item]);

  const {
    name,
    short_description = "",
    image,
    id,
    event_id,
    film_id,
    coming_soon,
    nuggets,
    type,
    tenant_slug,
    tenant_name,
    geographic_restrictions,
    sold_out,
    allow_preorder
  } = item || lastItem;

  if (tenant_slug && !festivalId) {
    festivalId = tenant_slug;
  }

  // Fix for orgs that set description and/or short_description to null when using API/sync
  let short_description_text = short_description;
  if (!short_description_text) {
    short_description_text = "";
  }

  return (
    <Transition
      show
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="relative bg-gray-700 rounded-md overflow-hidden mt-4 transition-all ease-in-out duration-300"
        style={{ height }}
      >
        <div className="absolute right-0 top-0 bottom-0 left-1/4 overflow-hidden bg-gray-700">
          {image ? (
            <img
              className="object-cover opacity-75 h-full w-full"
              src={image}
            />
          ) : null}
          <div className="block slider-muted-prev-background w-96 h-full absolute top-0 left-0" />
        </div>
        <div
          className="relative px-4 py-5 sm:px-6 sm:py-6 w-full sm:w-2/5 block"
          ref={r => {
            ref.current = r;
          }}
        >
          {isDiscoveryList && type === "screening" ? (
            <h3 className="font-base text-base text-gray-200 leading-7 mb-1 no-translate">
              {tenant_name}
            </h3>
          ) : null}
          <h2 className="font-bold text-2xl text-white leading-7 mb-2 no-translate">
            {name}
          </h2>
          {geographic_restrictions?.length ? (
            <p className="my-3">
              <div className="inline-block px-2 py-1 rounded text-sm font-medium text-gray-700 bg-gray-400">
                Only available in{" "}
                {geographic_restrictions.map(r => r.name).join(", ")}
              </div>
            </p>
          ) : null}
          <p className="text-gray-200 no-translate">
            {short_description_text.substring(0, 340)}
            {short_description_text.length > 340 ? "..." : ""}
          </p>
          {nuggets ? (
            <p className="text-gray-400 mt-2 no-translate">
              {generateNuggetsSummary(nuggets)}
            </p>
          ) : null}
          <Link
            href={
              type === "channel"
                ? "/[festivalId]"
                : "/[festivalId]/play/[...programId]"
            }
            as={
              type === "channel"
                ? `/${tenant_slug}`
                : `/${festivalId}/play/${
                    event_id ? [event_id, film_id].join("/") : id
                  }`
            }
          >
            <a className="inline-block mt-4 whitespace-no-wrap">
              {type === "channel" ? (
                <>
                  <Button size="huge">Enter channel</Button>
                </>
              ) : (
                <>
                  <Button size="huge" className="mr-3 sm:mr-4">
                    Learn more
                  </Button>
                  {coming_soon && !allow_preorder ? null : (<Button size="huge" className="" disabled={sold_out}>
                    {sold_out
                      ? "Sold out"
                      : coming_soon
                      ? "Pre-order now"
                      : "Watch now"}
                  </Button>)}
                </>
              )}
            </a>
          </Link>
        </div>
      </div>
    </Transition>
  );
}
