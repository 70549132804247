import MediaItem from "./MediaItem";
import classNames from "classnames";

export default function MediaGrid({
  items,
  festivalId,
  displayMode = "default",
  colorPalette = "default",
  onItemClick,
  ...rest
}) {
  return (
    <div
      className={classNames(
        "-m-2",
        displayMode === "poster"
          ? "grid justify-center sm:justify-between"
          : "flex flex-wrap",
        displayMode === "big_poster" ? "-mt-2 -mb-4 justify-center" : ""
      )}
      style={
        displayMode === "poster"
          ? { gridTemplateColumns: "repeat(auto-fill, 11rem)" }
          : undefined
      }
    >
      {items.map(item => (
        <div
          key={
            item.event_id && item.film_id
              ? `${item.event_id}-${item.film_id}`
              : item.id
          }
          className={
            displayMode === "poster"
              ? "w-auto"
              : classNames(
                  displayMode === "big_poster" ? "w-1/2" : "w-full",
                  "sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mb-4"
                )
          }
        >
          <MediaItem
            item={item}
            festivalId={festivalId}
            displayMode={displayMode}
            colorPalette={colorPalette}
            isGrid={true}
            onClick={onItemClick ? () => onItemClick(item) : undefined}
            {...rest}
          />
        </div>
      ))}
    </div>
  );
}
