import classNames from "classnames";
import moment from "moment";
import { formatShortDuration } from "../util";
import { leaving_soon_ms } from "../config";

export default function LiveBadge({
  start_time,
  end_time,
  livestream_mode,
  className,
  comingSoon,
  verbose
}) {
  const now = new Date();
  const start = new Date(start_time);
  let end = null;
  if (end_time) {
    end = new Date(end_time);
  }
  const isInFuture = now < start;
  const isNow = !isInFuture && now <= end;
  const isPast = now > end;
  const isComingSoonVerySoon =
    comingSoon && isInFuture && start - now <= leaving_soon_ms;
  let isEndingVerySoon = null;
  if (end) {
    isEndingVerySoon = isNow && end - now <= leaving_soon_ms;
  }

  return (
    <span
      className={classNames(
        "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 no-translate",
        comingSoon
          ? `bg-green-100 text-green-800`
          : isPast
          ? "bg-blue-100 text-blue-800"
          : "bg-red-100 text-red-800",
        className
      )}
    >
      {isNow && (
        <svg
          className="-ml-1 mr-1.5 h-2 w-2 text-red-600"
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx="4" cy="4" r="3" />
        </svg>
      )}
      {isNow
        ? isEndingVerySoon && !livestream_mode
          ? `${"Leaving in "}${formatShortDuration(end - now)}`
          : "Live now"
        : isInFuture
        ? comingSoon
          ? `${verbose ? "Coming " : ""}${
              isComingSoonVerySoon
                ? `${verbose ? "in " : "Coming in "}${formatShortDuration(
                    start - now
                  )}`
                : moment(start).format("ddd MMM Do")
            }`
          : `Live ${moment(start).fromNow()}`
        : isPast
        ? livestream_mode === "external"
          ? "External livestream"
          : "Recorded"
        : "Live"}
    </span>
  );
}
